import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5f889cd2"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src", "onClick"];
const _hoisted_2 = ["src", "onClick"];
const _hoisted_3 = {
  key: 2,
  class: "list4"
};
const _hoisted_4 = ["src", "onClick"];
const _hoisted_5 = {
  class: "pop-content list5"
};
const _hoisted_6 = {
  class: "list5-div"
};
const _hoisted_7 = {
  class: "img-div"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = ["src", "onClick"];
const _hoisted_10 = {
  key: 3,
  class: "list6"
};
const _hoisted_11 = {
  class: "img-div"
};
const _hoisted_12 = ["src", "onClick"];
const _hoisted_13 = {
  class: "title"
};
const _hoisted_14 = {
  key: 4,
  class: "notice-div"
};
const _hoisted_15 = ["onClick"];
const _hoisted_16 = {
  class: "img-div"
};
const _hoisted_17 = {
  class: "title"
};
const _hoisted_18 = {
  key: 6,
  class: "ads-text"
};
const _hoisted_19 = {
  key: 7,
  class: "list12"
};
const _hoisted_20 = {
  key: 8,
  class: "list13"
};
const _hoisted_21 = {
  key: 9,
  class: "list14"
};
const _hoisted_22 = ["src", "onClick"];
const _hoisted_23 = {
  key: 0,
  class: "list15"
};
const _hoisted_24 = {
  class: "right-div"
};
const _hoisted_25 = {
  key: 11,
  class: "list26"
};
const _hoisted_26 = ["src", "onClick"];
import { ref, computed, watch } from 'vue';
import { store } from "../utils/store.js";
import api from "@/api/shipin2/index.js";
export default {
  __name: 'AdsPictures',
  props: {
    list: {
      default() {
        return [];
      }
    },
    type: {
      default: 1
    },
    number: {
      default: 0 //广告显示的个数，默认0全部
    },

    random: {
      default: false //是否打乱数组
    }
  },

  emits: ['close', 'onload', 'imgLoad'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const swiper = ref();
    const seconds = ref(6 * 1000);
    const shuffleArray = array => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // 交换元素
      }

      return array;
    };
    const adsList = computed(() => {
      let list = store.adsList.filter(v => v.class_id == props.type);
      if (props.type === 3 && list.length) {
        list = [list[Math.floor(Math.random() * list.length)]];
      }

      // 如果传递了个数。
      if (props.number != 0 && list.length) {
        //console.log('list-y', list)
        //console.log('props.number', props.number, 'props.type', props.type)
        list = list.splice(0, props.number);
        //console.log('list-slice', list)
      }

      //是否随机
      if (props.random && list.length) {
        list = shuffleArray(list); //打乱数组
      }

      return list;
    });
    const imgLoad = index => {
      if (index === 0) {
        console.log('加载完成');
        seconds.value = 6 * 1000;
        emits('imgLoad');
      }
    };
    const clickImg = item => {
      if (item.url) {
        reportAds(item);
        window.location.href = item.url;
      }
    };

    // 首页弹窗广告 默认展示第一个 关闭当前展示下一个 当前为最后一个关闭弹窗
    const popAd = ref({
      show: false,
      showIndex: 0,
      list: computed(() => {
        const filterList = store.adsList.filter(v => v.class_id == 5);
        return filterList;
      }),
      close: index => {
        popAd.value.showIndex = index + 1;
        if (index === popAd.value.list.length - 1) {
          popAd.value.show = false;
          emits('onload');
        }
      }
    });
    const reportAds = async item => {
      await api.reportAds({
        ad_ids: item.id,
        report_type: 1
      });
    };
    watch(() => popAd.value.list, v => {
      if (props.type === 5) {
        if (!store.isDev() && sessionStorage.getItem('popAd')) return;
        // 增加判断接口返回，is_pop=1才显示弹窗。=1 弹出公告，  = 0 不弹出
        if (v.length > 0 && store.config.is_pop == 1) {
          popAd.value.show = true;
          emits('onload');
        } else {
          //emits('onload')
        }
        sessionStorage.setItem('popAd', 0);
      }
    });
    const show14 = ref(true);
    const isResize = ref(false);
    const change = () => {
      if (!isResize.value) {
        swiper.value?.resize();
        isResize.value = true;
      }
    };
    return (_ctx, _cache) => {
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_text_ellipsis = _resolveComponent("van-text-ellipsis");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_tag = _resolveComponent("van-tag");
      const _component_van_count_down = _resolveComponent("van-count-down");
      return _openBlock(), _createElementBlock(_Fragment, null, [[1, 23, 27].includes(__props.type) ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        ref_key: "swiper",
        ref: swiper,
        class: "my-swipe list1",
        autoplay: 3000,
        "indicator-color": "white",
        onChange: change
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_1)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 512)) : _createCommentVNode("", true), [3].includes(__props.type) ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [adsList.value.length ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        class: "my-swipe3",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_2)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), __props.type === 4 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("img", {
          src: item.icon,
          alt: "",
          onClick: $event => clickImg(item)
        }, null, 8, _hoisted_4)]);
      }), 128))])) : _createCommentVNode("", true), _createVNode(_component_van_popup, {
        show: popAd.value.show,
        "onUpdate:show": _cache[0] || (_cache[0] = $event => popAd.value.show = $event),
        "z-index": "2000",
        "close-on-click-overlay": false,
        class: "pop-ad",
        style: {
          width: '80%',
          background: 'none'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_Transition, {
            key: index,
            name: "animate__animated animate__zoomOut",
            "enter-active-class": "animate__zoomIn",
            "leave-active-class": "animate__zoomOut"
          }, {
            default: _withCtx(() => [_withDirectives(_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
              class: "close-icon-div",
              onClick: $event => popAd.value.close(index)
            }, [_createVNode(_component_van_icon, {
              class: "close-icon",
              name: "cross"
            })], 8, _hoisted_8), _createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_9)])], 512), [[_vShow, popAd.value.showIndex === index]])]),
            _: 2
          }, 1024);
        }), 128))])]),
        _: 1
      }, 8, ["show"]), [6, 24, 28].includes(__props.type) && adsList.value.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createElementVNode("div", _hoisted_11, [_createElementVNode("img", {
          src: item.icon,
          alt: "",
          onClick: $event => clickImg(item)
        }, null, 8, _hoisted_12)]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_van_text_ellipsis, {
          content: item.name
        }, null, 8, ["content"])])]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 9 && adsList.value.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          onClick: $event => clickImg(item)
        }, _toDisplayString(item.name), 9, _hoisted_15);
      }), 128))])])) : _createCommentVNode("", true), __props.type === 10 && adsList.value.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 5
      }, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "list10",
          key: index
        }, [_createElementVNode("div", _hoisted_16, [_createVNode(_component_van_image, {
          class: "img",
          "lazy-load": "",
          fit: "cover",
          src: item.icon,
          onClick: $event => clickImg(item)
        }, null, 8, ["src", "onClick"])]), _createElementVNode("div", _hoisted_17, _toDisplayString(item.name), 1)]);
      }), 128)) : _createCommentVNode("", true), [11, 25, 29].includes(__props.type) ? (_openBlock(), _createElementBlock("div", _hoisted_18, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#cce5ff",
          "text-color": "#004085",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 12 ? (_openBlock(), _createElementBlock("div", _hoisted_19, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#222",
          "text-color": "#c6c6c6",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 13 ? (_openBlock(), _createElementBlock("div", _hoisted_20, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_van_tag, {
          key: index,
          type: "primary",
          color: "#222",
          "text-color": "#c6c6c6",
          onClick: $event => clickImg(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))])) : _createCommentVNode("", true), __props.type === 14 && adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_21, [show14.value ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        class: "my-swipe14",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_22), _createVNode(_component_van_icon, {
              name: "cross",
              class: "popup-close-icon",
              onClick: _cache[1] || (_cache[1] = $event => show14.value = false)
            })]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true)])) : _createCommentVNode("", true), __props.type === 15 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 10
      }, [adsList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [_createElementVNode("span", null, [_createVNode(_component_van_count_down, {
        time: seconds.value,
        format: "ss秒",
        onFinish: _cache[2] || (_cache[2] = $event => _ctx.$emit('close'))
      }, null, 8, ["time"])]), _createElementVNode("span", {
        onClick: _cache[3] || (_cache[3] = $event => _ctx.$emit('close'))
      }, "跳过")]), adsList.value.length ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        class: "my-swipe15",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createVNode(_component_van_image, {
              "lazy-load": "",
              src: item.icon,
              onClick: $event => clickImg(item),
              onLoad: $event => imgLoad(index)
            }, null, 8, ["src", "onClick", "onLoad"]), _createVNode(_component_van_icon, {
              name: "cross",
              class: "popup-close-icon",
              onClick: _cache[4] || (_cache[4] = $event => show14.value = false)
            })]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), [26, 30].includes(__props.type) ? (_openBlock(), _createElementBlock("div", _hoisted_25, [show14.value ? (_openBlock(), _createBlock(_component_van_swipe, {
        key: 0,
        class: "my-swipe26",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adsList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              src: item.icon,
              alt: "",
              onClick: $event => clickImg(item)
            }, null, 8, _hoisted_26), _createVNode(_component_van_icon, {
              name: "cross",
              class: "popup-close-icon",
              onClick: _cache[5] || (_cache[5] = $event => show14.value = false)
            })]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 64);
    };
  }
};